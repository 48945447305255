import { useRef, useEffect, useState } from 'react';

function App() {
  const button = useRef(null);
  const [clientid] = useState('628bb05bc5007d001b7108ab');
  const [flowid] = useState('65f32d9689eb89001c567e2b');

  useEffect(() => {
    if (button.current) {
      button.current.addEventListener('metamap:loaded', ({ detail }) => console.log('[METAMAP] Loaded', detail));
      button.current.addEventListener('metamap:userStartedSdk', ({ detail }) => console.log('[METAMAP] User started verification', detail));
      button.current.addEventListener('metamap:userFinishedSdk', ({ detail }) => console.log('[METAMAP] User finished verification', detail));
      button.current.addEventListener('metamap:exitedSdk', ({ detail }) => console.log('[METAMAP] User exited verification', detail));
    }
  }, [button]);

  return (
    <div>
      <div style={{ color: '#041E41', fontWeight: 'bold', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: 'flex', marginBottom: '5px', textAlign: 'center', fontFamily: 'Lato', fontSize: '20px'}}>Para continuar, haz clic</div>
      <div style={{ color: '#041E41', fontWeight: 'bold', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: 'flex', marginBottom: '200px', textAlign: 'center', fontFamily: 'Lato', fontSize: '20px'}}>en Verifícame</div>

      <metamap-button
        ref={button}
        clientid={clientid}
        flowid={flowid}
        color={'#FF7454'}
      >
      </metamap-button>
    </div>
  );
}

export default App;
